<template>
    <el-dialog :title="method == 0 ? '新增Banner':'编辑Banner'" width="50%" :close-on-click-modal="false"
               :visible.sync="visible">
        <el-form size="small" :model="addEditForm" ref="addEditForm" label-width="140px" class="p_r2">
            <el-form-item label="标题：" prop="bannerTitle"
                          :rules="[{ required: true, message: '请输入标题', trigger: 'blur' }]">
                <el-input v-model.trim="addEditForm.bannerTitle" maxlength="100"
                          placeholder="请输入标题（限100字）" clearable></el-input>
            </el-form-item>
            <el-form-item label="适用于：" prop="terrace">
                <el-radio-group v-model="addEditForm.terrace" @input="terraceChange">
                    <el-radio label="0">官网</el-radio>
                    <el-radio label="1">小程序</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="Banner：" prop="bannerImageUrl"
                          :rules="[{ required: true, message: '请上传图片', trigger: 'change' }]">
                <ImgUpload v-if="visible" :imgAskObj="imgUploadRequire" @getImgList="getImgList"></ImgUpload>
            </el-form-item>
            <el-form-item label="链接地址：" prop="bannerHref"
                          :rules="[{ required: true, message: '请输入链接地址', trigger: 'blur' }]">
                <el-input v-model.trim="addEditForm.bannerHref" maxlength="500"
                          placeholder="请输入链接地址" clearable></el-input>
            </el-form-item>
            <el-form-item label="排序：" prop="sort">
                <el-input v-model.trim="addEditForm.sort" maxlength="3"
                          placeholder="请输入排序" clearable @blur="sortChange"></el-input>
            </el-form-item>
            <el-form-item label="有效期：">
                <el-date-picker class="m_r1" size="small"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                v-model="termOfValidity"
                                type="datetimerange"
                                range-separator="至"
                                start-placeholder="有效期开始日期"
                                end-placeholder="有效期结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态：" prop="state">
                <el-switch v-model="addEditForm.state"
                           :active-value="'0'"
                           :inactive-value="'1'"
                ></el-switch>
            </el-form-item>
            <div v-if="method == 1">
                <el-form-item label="添加人：">{{editInfo.createUser}}</el-form-item>
                <el-form-item label="添加时间：">{{editInfo.createTime}}</el-form-item>
                <el-form-item label="最后修改时间：">{{editInfo.updateTime}}</el-form-item>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="visible = false">关闭</el-button>
                <el-button size="small" type="primary" @click="submit()" v-noMoreClick>提交</el-button>
            </span>
    </el-dialog>
</template>

<script>
    import ImgUpload from "@/components/imgUpload/imgUpload";

    export default {
        components: {ImgUpload},
        data() {
            return {
                // 新增、编辑
                visible: false,
                termOfValidity: [],
                addEditForm: {
                    id: '',
                    bannerTitle: '',
                    bannerImageUrl: '',
                    bannerHref: '',
                    terrace: '0', //0官网 1小程序
                    sort: '1',
                    state: '',
                    termOfValidityEnd: '',
                    termOfValidityStart: '',
                },
                method: 0,
                editInfo: {},
                imgUploadRequire: {
                    imgCount: 1, // 上传数量 0表示不限制数量
                    imgList: [], // 已上传的文件
                    uploadPath: "course",
                    imgSize: '1920*1080', // 图片尺寸
                    listType: 'picture-card', // 图片缩略图类型
                    imgNum: 10, // 上传文件兆数 0表示不限兆数
                    pictureSize: true, //是否开启验证图片尺寸
                },
            }
        },
        watch: {
            'addEditForm.terrace'(val) {
                if (val == 0) {
                    this.imgUploadRequire.imgSize = '1920*1080'
                } else {
                    this.imgUploadRequire.imgSize = '750*900'
                }
            },
        },
        methods: {
            init(row, method) {
                this.imgUploadRequire.imgList = []
                this.termOfValidity = []
                this.visible = true
                this.method = method
                this.$nextTick(() => {
                    this.addEditForm.id = ''
                    this.$refs.addEditForm.resetFields();
                    if (method == 1) {
                        this.imgUploadRequire.imgList.push({
                            url: row.bannerImageUrl
                        })
                        this.termOfValidity.push(row.termOfValidityStart || '')
                        this.termOfValidity.push(row.termOfValidityEnd || '')
                        this.recover(this.addEditForm, row);
                        this.editInfo = row
                    }
                })
            },
            // 适用于切换
            terraceChange(val) {
                this.addEditForm.bannerImageUrl = ''
                this.imgUploadRequire.imgList = []
            },
            // 提交
            submit() {

                this.$refs['addEditForm'].validate((valid) => {
                    if (valid) {
                        let termOfValidityStart = this.termOfValidity ? this.termOfValidity[0] : ''
                        let termOfValidityEnd = this.termOfValidity ? this.termOfValidity[1] : ''
                        if (termOfValidityStart != '' && new Date(termOfValidityEnd) <= new Date(termOfValidityStart)) {
                            this.$message.error('结束时间必需要大于开始时间')
                            return false
                        }
                        if (termOfValidityStart != '' && new Date(termOfValidityStart) < new Date() || new Date(termOfValidityEnd) < new Date()) {
                            this.$message.error('有效期必需要大于当前时间')
                            return false
                        }
                        this.addEditForm.termOfValidityStart = termOfValidityStart
                        this.addEditForm.termOfValidityEnd = termOfValidityEnd
                        let apiStr = 'websitebannerSave',
                            type = 'post'
                        if (this.method == '1') {  // 编辑
                            apiStr = 'websitebannerUpdateById'
                            type = 'put'
                        }
                        this.$axios(this.api.website[apiStr], this.addEditForm, type).then(res => {
                            if (res.status) {
                                this.$message.success(res.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                })
            },
            // 排序修改
            sortChange() {
                let value = this.addEditForm.sort.replace(/\D/g, '')
                if (value < 1 && value != '') {
                    this.$set(this.addEditForm, 'sort', 1)
                } else {
                    this.$set(this.addEditForm, 'sort', value)
                }
            },
            // 获取上传图片数据
            getImgList(data) {
                if (data.length > 0) {
                    this.addEditForm.bannerImageUrl = data[0].imgUrl
                    this.$refs.addEditForm.clearValidate('bannerImageUrl');
                } else {
                    this.addEditForm.bannerImageUrl = ''
                }
            },
        },
    }
</script>

<style scoped>
</style>
